import Head from 'next/head'
import Link from 'next/link'
import { compareDesc, format, parseISO } from 'date-fns'
import { allPosts, Post } from 'contentlayer/generated'
import { Title } from 'src/components/Title'

export async function getStaticProps() {
  const posts = allPosts.sort((a, b) => {
    return compareDesc(new Date(a.date), new Date(b.date))
  })
  return { props: { posts } }
}

function PostCard(post: Post) {
  return (
    <div className="inline-flex space-x-1 items-baseline">
      <h2 className="underline text-lg text-blue-600 hover:text-rose-900 visited:text-rose-700">
        <Link href={post.url} >
          <a>{post.title}</a>
        </Link>
      </h2>
      <time dateTime={post.date} className="text-xs text-gray-500">
        &#8212; {format(parseISO(post.date), 'LLLL d, yyyy')}
      </time>
    </div>
  )
}

export default function Home({ posts }: { posts: Post[] }) {
  return (
    <>
      <Head>
        <title>Jonny Gamba's personal website</title>
      </Head>

      <main className="px-8 py-2">
        <Title title="Posts" />
        <ul className="list-inside list-disc">
          {posts.map((post, idx) => (
            <li><PostCard key={idx} {...post} /></li>
          ))}
        </ul>
      </main>
    </>
  )
}
