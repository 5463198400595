import React from 'react'

export const Title = ({ title, subtitle = null }: { title: string, subtitle?: React.ReactNode }) => {
  return (
    <div>
      <h1 className='text-6xl pb-1'>{title}</h1>
      {subtitle}
      <hr className='pb-8' />
    </div>
  )
}
